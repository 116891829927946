// EHX Core Platform Dashboards
// export const TOOLS = '/tools'
export const TOOLS_DASHBOARD = '/tools/dashboard'

export const CONNECTORS = '/tools/connectors'
export const SERVICES = '/tools/services'
export const TRANSFORMERS = '/tools/transformers'
export const JOBS = '/tools/jobs'
export const RECIPES = '/tools/recipes'
export const RECIPE_STEPS = '/tools/recipe-steps'
export const TRIGGERS = '/tools/triggers'
export const CONTENT_CATALOG = '/tools/content-catalog'
export const IMAGE_CATALOG = '/tools/image-catalog'
// export const ASSOCIATIONS = '/tools/associations'
// export const CATEGORIES = '/tools/categories'
// export const DESCRIPTORS = '/tools/descriptors'
// export const FACADES = '/tools/facades'
// export const INDICES = '/tools/indices'
// export const TEMPLATES = '/tools/templates'
export const CONTENT_TYPES = '/tools/content-types'
export const CONTENT_ASSOCIATIONS = '/tools/content-associations'
export const CONTENT_CATEGORIES = '/tools/content-categories'
export const CONTENT_DESCRIPTORS = '/tools/content-descriptors'
export const CONTENT_FACADES = '/tools/content-facades'
export const CONTENT_INDICES = '/tools/content-indices'
export const CONTENT_TEMPLATES = '/tools/content-templates'



export const PATIENTS = '/patients'
export const PROVIDERS = '/providers'
export const APPOINTMENTS = '/appointments'
export const APPOINTMENTS_CALENDAR = '/appointments-calendar'
export const PROVIDER_APPOINTMENTS_CALENDAR = '/appointments-calendar-provider'
export const VISITS_CALENDAR = '/visits-calendar'
export const VISITS = '/visits'
export const PAYMENTS = '/payments'
export const LABS = '/labs'
export const PRESCRIPTIONS = '/prescriptions'
export const HOME = '/home'
export const CHAT = '/chat/:id'
export const CHAT_AI = '/chat-ai'
export const HEALTHCARE_FACILITY = '/heathcare-facilities'
export const SUBSCRIBERS = '/subscribers'
export const MAP = '/map'
export const SUBSCRIPTIONS = '/subscriptions'
export const PHARMACISTS = '/pharmacists'
export const CARE_TEAMS = '/care-teams'
export const REFERRAL = '/referral'
export const HEALTHCARE_WORKERS = '/healthcare-worker'
export const MANAGE_PRESCRIPTIONS = '/manage-prescriptions'
export const ADMIN = '/admin'
// Ehx Form Routes - Management
export const MANAGE_PROVIDER = '/manage-provider'
export const MANAGE_APPOINTMENT = '/manage-appointment'
export const MANAGE_CARE_TEAMS = '/manage-care-teams/:id'
export const MANAGE_VISIT = '/manage-visit'
export const MANAGE_PATIENT = '/manage-patient'
export const MANAGE_PRESCRIPTION = '/manage-prescription'
export const MANAGE_LAB = '/manage-lab'
export const MANAGE_PAYMENT = '/manage-payment'
export const MANAGE_HEATHCARE_FACILITY = '/manage-healthcare-facility'
export const MANAGE_SUBSCRIBER = '/manage-subscriber'

// Ehx Form Routes - Create
export const CREATE_PROVIDER = '/create-provider'
export const CREATE_APPOINTMENT = '/appointments/create'
export const CREATE_CARE_TEAMS = '/care-teams-create'
export const CREATE_VISIT = '/create-visit'
export const CREATE_PATIENT = '/create-patient'
export const CREATE_PRESCRIPTION = '/create-prescription'
export const CREATE_LAB = '/create-lab'
export const CREATE_PAYMENT = '/create-payment'
export const CREATE_HEALTHCARE_FACILITY = '/create-healthcare-facility'

// Ehx Form Routes - Edit
export const UPDATE_APPOINTMENT = '/appointments/:id'
export const UPDATE_VISIT = '/visits/:id'
export const UPDATE_PATIENT = '/patients/:id'
export const UPDATE_PROVIDER = '/providers/:id'
export const UPDATE_HEALTHCARE_FACILITY = '/healthcare-facilities/:id'

// Ehx UserProfile routes
export const PROVIDER_SETTINGS = '/provider-settings'
export const SUBSCRIBER_SETTINGS = '/subscriber-settings'
export const NOTIFICATIONS = '/notifications'
export const LOGOUT = '/logout'
export const ACCOUNTS = '/accounts'
export const USER_PROFILE = '/user-profile'
export const DOCUMENTS = '/docs'

// Ehx Admin Routes
export const ADMIN_ALL_SUBSCRIBERS = '/admin/subscribers'
export const ADMIN_NEW_SUBSCRIBER = '/admin/subscribers/new'
export const ADMIN_SUBSCRIBER_DETAILS = '/admin/subscribers/:id'

// Claims
export const CLAIMS = '/claims/dashboard'

// Utilization Management
export const UTILIZATION_MANAGEMENT = '/um/dashboard'
